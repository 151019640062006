body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

header {
	background-image: linear-gradient(90deg, #396cbd 0%, #7bc656 100%);
}
.column-id,
.column-note {
	width: 20px;
}
.iconDashboard {
	color: white;
	padding: 10px;
	font-size: 70px !important;
	border-radius: 10px;
	position: absolute;
	top: -10px;
	left: 44px;
}
.shopsSelectorTextField > div > div > span {
	width: 100%;
}
.shopsSelectorTextField > div > label {
	display: none;
}

.edit-page .ra-delete-button {
	position: absolute;
	right: 15px;
}

.customCancelButton {
	color: rgba(0, 0, 0, 0.26) !important;
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.12);
	padding: 7px;
	margin-left: 10px;
}

.UrlCtoCIcon {
	padding: 4px;
}
.UrlCtoCIcon:hover {
	color: #396cbd;
}
